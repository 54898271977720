/**
 *
 * create all core services for all modules
 * contains the startup code of the application APP_INITIALIZERs
 */

import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {APP_INITIALIZER, importProvidersFrom, LOCALE_ID, NgModule, Optional, SkipSelf} from '@angular/core';
import {MatNativeDateModule} from '@angular/material/core';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';

import {HDMUI_THEMES, provideHdmui, ThemeService} from '@heidelberg/hdmui-angular';
import {
  ClientConfiguration as SubscriptionClientConfig,
  DocumentsClient,
  ExtraProductsClient,
  OpenDeliveriesClient,
  ProductBookingsClient,
  ProductConsumptionsClient,
  ProductCountingsClient,
  ProductsClient,
  SubscriptionInfoClient,
} from '@heidelberg/vmi-subscription-api-client';
import {
  CC_APP_AUTH_CONFIG,
  CC_SESSION_READY_CALLBACK,
  CCAppAuthConfig,
  CCAuthService,
  CCSession,
  provideCCAuth,
} from '@heidelberg/control-center-frontend-integration/auth';
import {ConfigService, ControlCenterNotificationModule} from '@heidelberg/control-center-frontend-integration';

import {SUBSCRIPTION_SERVICE_URL} from '@vmi/injection-tokens';

import {AppConfigService, PermissionService, UserService} from './services';
import {AcceptHeaderInterceptor, SubscriptionClientTokenInterceptor} from './interceptors';
import {createTranslateLoader, getLocale, initLangSettings} from './locale.support';
import {SessionAuthGuard} from './guards';
import {GlobalErrorHandler} from './error';
import {Ga4Service} from '../../../../src/app/google-analytics/ga4.service';
import {SubscriptionClientConfigurationFactory} from './configs';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        MatNativeDateModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SubscriptionClientTokenInterceptor,
            multi: true,
        },
        SubscriptionInfoClient,
        OpenDeliveriesClient,
        DocumentsClient,
        ProductBookingsClient,
        ProductsClient,
        ExtraProductsClient,
        ProductCountingsClient,
        ProductConsumptionsClient,
        UserService,
        SessionAuthGuard,
        provideHttpClient(withInterceptorsFromDi()),
        provideHdmui(),
        importProvidersFrom(ControlCenterNotificationModule),
        provideCCAuth(),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AcceptHeaderInterceptor,
            multi: true,
        },
        {
            provide: CC_APP_AUTH_CONFIG,
            useFactory: createAuthConfig,
            deps: [AppConfigService, ConfigService],
        },
        {
            provide: CC_SESSION_READY_CALLBACK,
            useFactory: sessionReadyHandler,
            deps: [PermissionService],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: onThemeInit,
            multi: true,
            deps: [ThemeService],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initLangSettings,
            multi: true,
            deps: [TranslateService, CCAuthService],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initGA4Service,
            deps: [Ga4Service],
            multi: true,
        },
        {
            provide: LOCALE_ID,
            useFactory: getLocale,
            deps: [TranslateService],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: GlobalErrorHandler,
            multi: true,
        },
        {
            provide: SubscriptionClientConfig,
            useFactory: SubscriptionClientConfigurationFactory,
            deps: [SUBSCRIPTION_SERVICE_URL],
        },
    ],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import it in the AppModule only');
        }
    }
}

function sessionReadyHandler(permissionService: PermissionService) {
    return async (session: CCSession) => {
        permissionService.buildRoleContexts(session);
    };
}

function createAuthConfig(service: AppConfigService, configService: ConfigService): CCAppAuthConfig {
    return {
        ccStage: service.getCCStage(),
        clientId: service.getOAuthClientId(),
        appId: 'vendor_managed_inventory',
        requiresOrganizationContext: true,
        authenticatedUrls: ['/api', window.location.origin + '/', configService.getNotificationSvcUrl()],
    };
}

function onThemeInit(service: ThemeService): () => void {
    return () => {
        const queryString = window.location.search;
        if (queryString) {
            const urlParams = new URLSearchParams(queryString);
            const theme = urlParams.get('theme');
            if (
                theme &&
                [HDMUI_THEMES.DARK_THEME_CLASS.toString(), HDMUI_THEMES.LIGHT_THEME_CLASS.toString()].includes(theme)
            ) {
                service.setTheme(theme);
            }
        }
    };
}

function initGA4Service(service: Ga4Service): () => void {
    return () => {
        service.listenToEvents();
    };
}
