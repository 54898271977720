import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { HdmuiComponentsModule } from '@heidelberg/hdmui-angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UCUI } from '../../../../../../src/app/google-analytics/ga4.models';

declare const UC_UI: UCUI;

@Component({
  standalone: true,
  selector: 'vmi-legal-line',
  template: `
        <hdmui-legal-line
            [imprintLink]="IMPRINT_LINK"
            [imprintText]="'*.imprint' | translate"
            [privacyPolicyLink]="PRIVACY_POLICY_LINK"
            [privacyPolicyText]="'*.privacy_policy' | translate"
            [termsOfUseLink]="TERMS_OF_USE_LINK"
            [termsOfUseText]="'*.terms_of_use' | translate"
        ><div customLinkContainer>
                <a (click)="showSecondLayer()">{{ '*.cookie.settings' | translate }}</a>
            </div></hdmui-legal-line>
    `,
  styles: [
    `
            :host {
                margin-top: auto;
            }
        `,
  ],
  imports: [HdmuiComponentsModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegalLineComponent {
  readonly #link =
    'https://www.heidelberg.com/global/de/global_content/heidelberg_customer_portal_1/home/terms_of_use_and_privacy_policy_1/country_list.html';
  readonly #translateService = inject(TranslateService);

  public IMPRINT_LINK = this.#link;
  public PRIVACY_POLICY_LINK = this.#link;
  public TERMS_OF_USE_LINK = this.#link;

  public showSecondLayer(): void {
    UC_UI.updateLanguage(this.#translateService.currentLang);
    UC_UI.showSecondLayer();
  }
}
