<hdmui-base-dialog
    [dialogIcon]="icon"
    [dialogTitle]="data.title"
    [dialogOptions]="option"
    [closeInHeaderVisible]="false"
    [class.warning-strong-dialog]="data.isWarningStrongIcon"
    (okClicked)="onOk()"
    (cancelClicked)="onCancel()"
    [okText]="'*.confirmDialog.button.yes' | translate"
    [cancelText]="'*.confirmDialog.button.no' | translate"
>
    <p>{{ data.text }}</p>
</hdmui-base-dialog>
