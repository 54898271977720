<hdmui-base-dialog
    dialogIcon="hdmui:trash"
    dialogTitle="{{ '*.button.remove' | translate }}"
    [dialogOptions]="option"
    [okText]="'*.action.confirm' | translate"
    [cancelText]="'*.action.cancel' | translate"
    (okClicked)="onOk()"
    (cancelClicked)="onCancel()"
>
    <vmi-dialog-scroll>
        <p>{{ value }}</p>
    </vmi-dialog-scroll>
</hdmui-base-dialog>
