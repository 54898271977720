<hdmui-base-dialog
    class="success-dialog"
    dialogIcon="hdmui:hookCircled"
    [dialogTitle]="'inventory.action.success' | translate"
    [closeInHeaderVisible]="false"
    [dialogOptions]="OPTION_OK"
    [okText]="'*.ok' | translate"
    (okClicked)="onOk()"
>
    <p>{{ text }}</p>
</hdmui-base-dialog>
