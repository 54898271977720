import { Injectable } from '@angular/core';
import { flatMap, uniq } from 'lodash';

import { CCPerson, CCPersonOrganizationMembership } from '@heidelberg/control-center-frontend-integration/cc';

import { PrintShop, Role, UserSession } from '@vmi/shared-models';

const rightsByControlCenterRole: { [name: string]: Role[] } = {
    mdf: [Role.MANAGING_DIRECTOR_FINANCE],
    cfo: [Role.CHIEF_FINANCIAL_OFFICER],
    buc: [Role.BUSINESS_CONTROLLER],
    sar: [Role.SALES_REPRESENTATIVE],
    pur: [Role.PURCHASER],
    vpo: [Role.VICE_PRESIDENT_OPERATIONS],
    mam: [Role.MAINTENANCE_MANAGER],
    mao: [Role.MAINTENANCE_OPERATOR],
    stm: [Role.STORE_MAN],
    tra: [Role.TRAINEE],
    adm: [Role.ADMINISTRATOR],
    own: [Role.OWNER, Role.ADMINISTRATOR],
    mem: [Role.MEMBER],
    ppl: [Role.PRODUCTION_PLANNER],
    ppm: [Role.PREPRESS_MANAGER],
    ppo: [Role.PREPRESS_OPERATOR],
    ppa: [Role.PREPRESS_ASSISTENT],
    cma: [Role.COLOR_MANAGER],
    prm: [Role.PRESSROOM_MANAGER],
    pro: [Role.PRESS_OPERATOR],
    pra: [Role.PRESS_ASSISTENT],
    pom: [Role.POSTPRESS_MANAGER],
    poo: [Role.POSTPRESS_OPERATOR],
    poa: [Role.POSTPRESS_ASSISTENT],
};

@Injectable()
export class UserService {
    #session: UserSession | undefined;

    public get userSession(): UserSession | undefined {
        return this.#session;
    }

    public get customers(): PrintShop[] {
        return this.userSession?.customers || [];
    }

    public initUserSession(person: CCPerson, selectedOrganizationId: string): void {
        const selectedMembership = person.memberships.find((m) => m.organization.id === selectedOrganizationId);

        if (selectedMembership) {
            const groupTagOfSelectedMembership = selectedMembership?.organization?.tags?.formerPrintshopGroupName;
            const activeMemberships = groupTagOfSelectedMembership
                ? person.memberships?.filter(
                    (m) => m.organization?.tags?.formerPrintshopGroupName === groupTagOfSelectedMembership
                )
                : [selectedMembership];

            this.#session = this.mkSession(person, selectedOrganizationId, activeMemberships);
        }
    }

    private mkSession(
        person: CCPerson,
        selectedOrganizationId: string,
        activeMemberships: CCPersonOrganizationMembership[]
    ): UserSession {
        return {
            personId: person.id,
            email: person.email,
            fullName: person.fullName,
            firstName: person.givenName || '',
            lastName: person.name,
            avatarUrl: person.avatarUrl,
            locale: person.locale,
            countryCode: person.countryCode,
            timezone: person.timezone,

            organizationId: selectedOrganizationId,
            allMemberships: person.memberships,
            customers: flatMap(activeMemberships, customersForMembershipCallback),
        };

        function customersForMembershipCallback(mem: CCPersonOrganizationMembership): UserSession['customers'][0][] {
            const customerNumbers = mem.organization.hdmCustomerNumbers || [];

            return customerNumbers.map((customerNumber) => ({
                name: mem.organization.name,
                customerNumber,
                rights: uniq(flatMap(mem.ccRoles, (role) => rightsByControlCenterRole[role] || [])),
            }));
        }
    }
}
