import { inject, Injectable } from '@angular/core';
import { filter, Observable, switchMap, take } from 'rxjs';
import { MimeType } from '@vmi/shared-models';
import { GlobalLoadingSpinnerService } from '@vmi/ui-smart';
import { DocumentsClient, FileResponse } from '@heidelberg/vmi-subscription-api-client';
import { PrintShopsService } from '@vmi/core';
import { FileDownloaderService } from '@vmi/utils';
import { CCAuthService } from '@heidelberg/control-center-frontend-integration/auth';

@Injectable({ providedIn: 'root' })
export class QrCodesExportService {
    readonly #globalLoadingSpinnerService = inject(GlobalLoadingSpinnerService);
    readonly #printShopsService = inject(PrintShopsService);
    readonly #documentsApi = inject(DocumentsClient);
    readonly #sessionService = inject(CCAuthService);
    readonly #fileDownloaderService = inject(FileDownloaderService);

    public exportQrCodes(productId?: string): void {
        this.#globalLoadingSpinnerService.startLoading();

        this.downloadQrCodesPdf(productId)
            .pipe(take(1))
            .subscribe((fileResponse) => {
                const customerNumber = this.#printShopsService.selectedPrintShopNumber;
                const fileName = productId
                    ? `QRCode_${customerNumber}_${productId}.pdf`
                    : `QRCodes_${customerNumber}.pdf`;

                this.#fileDownloaderService.download(fileResponse, fileName, MimeType.PDF);
                this.#globalLoadingSpinnerService.stopLoading();
            });
    }

    private downloadQrCodesPdf(productId?: string): Observable<FileResponse> {
        return this.#printShopsService.selectedPrintShop$.pipe(
            filter((selectedPrintshop) => !!selectedPrintshop),
            switchMap((selectedPrintshop) =>
                productId
                    ? this.#documentsApi.getMaterialCustomerProductQrCodePdf2(
                          selectedPrintshop?.customerNumber,
                          productId,
                          this.#sessionService.getCurrentUser()?.locale
                      )
                    : this.#documentsApi.getMaterialCustomerProductQrCodePdf(
                          selectedPrintshop?.customerNumber,
                          this.#sessionService.getCurrentUser()?.locale
                      )
            )
        );
    }
}
