import {ClientConfiguration} from 'heidelberg-assets-api-client';

export function SubscriptionClientConfigurationFactory(serviceUrl: string): ClientConfiguration {
    const config = new ClientConfiguration();

    // INFO: token is set using SubscriptionClientTokenInterceptor
    config.baseUrl = serviceUrl;

    return config;
}
