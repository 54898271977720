<hdmui-base-dialog
    dialogIcon="hdmui:info"
    dialogTitle="{{ '*.about' | translate }}"
    [dialogOptions]="option"
    [closeInHeaderVisible]="false"
    [okText]="'*.ok' | translate"
    (okClicked)="onOk()"
    (cancelClicked)="onCancel()"
>
    <div class="about-dialog__wrapper">
        <div class="about-dialog__row">
            <div class="about-dialog__header">
                <img class="about-dialog__icon" src="assets/svg/HDM-Inventory.svg" [alt]="data.appName" />
                <div class="about-dialog__text">
                    <h1>{{ data.appName }}</h1>
                </div>
            </div>
            <p class="small">{{ data.copyright }}</p>
        </div>
    </div>
</hdmui-base-dialog>
