import {inject, Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

import {SUBSCRIPTION_SERVICE_URL} from '@vmi/injection-tokens';
import {OrgTokenService} from '@vmi/core';

@Injectable()
export class SubscriptionClientTokenInterceptor implements HttpInterceptor {
    readonly #BASE_URL = inject(SUBSCRIPTION_SERVICE_URL);
    readonly #orgTokenService = inject(OrgTokenService);

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (req.url.includes(this.#BASE_URL)) {
            const clonedRequest = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.#orgTokenService.orgToken}`,
                },
            });
            return next.handle(clonedRequest);
        }

        return next.handle(req);
    }
}
