import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

import {MatButtonModule} from '@angular/material/button';
import {isEqual} from 'lodash';

import {HdmuiEmptyStatesModule} from '@heidelberg/hdmui-angular';
import {CustomDividerComponent} from '@vmi/ui-presentational';
import {Transaction, TransactionsFilters, TransactionStatus} from '@vmi/feature-transactions';

import {TransactionCardComponent} from '../transaction-card/transaction-card.component';
import {TransactionsFilterService} from '../../services/transactions-filter.service';

@Component({
    standalone: true,
    selector: 'vmi-transaction-cards',
    imports: [
        TransactionCardComponent,
        TranslateModule,
        MatButtonModule,
        HdmuiEmptyStatesModule,
        CustomDividerComponent,
    ],
    styleUrls: ['./transaction-cards.component.scss'],
    templateUrl: './transaction-cards.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionCardsComponent implements OnChanges {
    private readonly CHUNK_SIZE = 100;

    public openTransactions: Transaction[] = [];
    public completedTransactions: Transaction[] = [];
    public unknownTransactions: Transaction[] = [];
    public maxDisplayedTransactions = this.CHUNK_SIZE;
    public displayShowMoreButton = false;

    @Input()
    transactions!: Transaction[] | undefined;

    @Input()
    searchPhrase!: string;

    @Input()
    filters!: TransactionsFilters | undefined;

    @Output()
    cardClick = new EventEmitter<Transaction>();

    #previousTransactions: Transaction[] = [];

    constructor(private readonly transactionsFilterService: TransactionsFilterService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['transactions'] || changes['searchPhrase'] || changes['filters']) {
            const transactions: Transaction[] = changes['transactions']?.currentValue || this.transactions;
            const searchPhrase = changes['searchPhrase']?.currentValue || this.searchPhrase;
            const filters = changes['filters']?.currentValue || this.filters;

            if (transactions !== undefined) {
                if (this.shouldUpdateTransactions(transactions, !!changes['filters'])) {
                    this.updateData(searchPhrase, filters, transactions);
                }
                this.#previousTransactions = transactions;
            }
        }
    }

    private extractOpenTransactions(allTransactions: Transaction[]): Transaction[] {
        const openTransactionsStatuses = [TransactionStatus.PROCESSING, TransactionStatus.PENDING];

        return allTransactions.filter((transaction) => openTransactionsStatuses.includes(transaction.status));
    }

    private extractCompletedTransactions(allTransactions: Transaction[]): Transaction[] {
        const completedTransactionsStatuses = [TransactionStatus.COMPLETED, TransactionStatus.REJECTED];

        return allTransactions.filter((transaction) => completedTransactionsStatuses.includes(transaction.status));
    }

    private extractUnknownTransactions(allTransactions: Transaction[]): Transaction[] {
        const unknownTransactions = [TransactionStatus.UNKNOWN];

        return allTransactions.filter((transaction) => unknownTransactions.includes(transaction.status));
    }

    public onCardClick(transaction: Transaction): void {
        this.cardClick.emit(transaction);
    }

    public onShowMoreButtonClick(): void {
        if (!this.isFurtherShowMoreActionAllowed()) {
            this.displayShowMoreButton = false;
        }

        this.maxDisplayedTransactions += this.CHUNK_SIZE;
        this.updateData(this.searchPhrase, this.filters, this.transactions, false);
    }

    private isFurtherShowMoreActionAllowed() {
        return this.transactions && this.maxDisplayedTransactions + this.CHUNK_SIZE <= this.transactions.length;
    }

    private updateData(
        searchPhrase: string,
        filters: TransactionsFilters | undefined,
        transactions: Transaction[] = [],
        resetDisplayedProductsNo = true
    ): void {
        const allTransactions = this.prepareData(transactions, searchPhrase, filters);

        this.openTransactions = this.extractOpenTransactions(allTransactions);
        this.completedTransactions = this.extractCompletedTransactions(allTransactions);
        this.unknownTransactions = this.extractUnknownTransactions(allTransactions);

        const shouldDisplayButtonWhenDataDefault =
            ((!!this.transactions && this.transactions?.length) || 0) > allTransactions.length;
        const shouldDisplayButtonWhenDataFiltered = this.maxDisplayedTransactions <= allTransactions.length;

        this.displayShowMoreButton =
            searchPhrase || (filters && Object.values(filters).some((filter) => filter.length))
                ? shouldDisplayButtonWhenDataFiltered
                : shouldDisplayButtonWhenDataDefault;
        this.maxDisplayedTransactions = resetDisplayedProductsNo ? this.CHUNK_SIZE : this.maxDisplayedTransactions;
    }

    private prepareData(
        transactions: Transaction[],
        searchPhrase: string,
        filters: TransactionsFilters | undefined
    ): Transaction[] {
        const searchedData = this.transactionsFilterService.applySearchPhrase(transactions, searchPhrase);
        const preparedData = this.transactionsFilterService.applyFilters(searchedData, filters);

        return preparedData?.slice(0, this.maxDisplayedTransactions);
    }

    private shouldUpdateTransactions(transactions: Transaction[], filtersChange: boolean): boolean {
        return filtersChange || !isEqual(transactions, this.#previousTransactions);
    }
}
