import {inject, Injectable} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import {CCAuthService} from '@heidelberg/control-center-frontend-integration/auth';

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class OrgTokenService {
    readonly #ccAuth = inject(CCAuthService);

    #orgToken?: string;

    constructor() {
        this.#ccAuth.orgMembershipTokenChanged$.pipe(untilDestroyed(this)).subscribe((token) => {
            this.orgToken = token.access_token;
        });
    }

    public get orgToken(): string | undefined {
        return this.#orgToken;
    }

    private set orgToken(token: string) {
        this.#orgToken = token;
    }
}
