export const LOCALSTORAGE_LOCALE_KEY = 'Language';
export const ENGLISH_LOCALE = 'en';
export const ENGLISH_US_LOCALE = 'en-US';
export const ENGLISH_UK_LOCALE = 'en-GB';
export const AVAILABLE_LANGUAGES = [
    'cs',
    'de',
    'es',
    'fr',
    'hu',
    'de',
    'it',
    'ja',
    'ko',
    'nl',
    'pl',
    'pt',
    'ro',
    'ru',
    'th',
    'zh',
    'en',
];
